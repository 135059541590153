import { registerApplication, start } from "single-spa";

function register(name) {
    registerApplication(name, () => System.import("@swecon/" + name), () => !!document.querySelector("#single-spa-application\\:" + name));
}

register("header-search-box");
register("header-search-box-mobile");
register("customer-picker");
register("select-machine");
register("exploded-view");
register("checkout");
register("spare-part-variation-list");
register("search-result-list");
register("quantity-selector");
register("pop-up");
register("mini-cart");
register("order-confirmation");
register("inventory-label");
register("pdp-accordion");
register("navigation-standard-exploded");
register("recommended-spare-parts");
register("sorting-dropdown");
register("exploded-view-machines-list");
register("service-kit-bundle");
register("machine-selector-button");
register("faq-accordion");
register("exploded-view-page");
register("quick-order");
register("machine-sales-header-search-box");
register("machine-sales-header-search-box-mobile");
register("machine-pdp-carousel");
register("scroll-group");
register("machines-sales-pdp-technical-information");
register("finance-button");
register("recommended-machines");
register("machine-quantity-selector");
register("machine-sales-checkout");
register("machine-sales-order-confirmation");
register("pdp-availability");
register("machines-search-results");
register("machines-list-plp");
register("pdp-image-slider");
register("detail-page-buttons");
// NEW PROJECT HERE DO NOT REMOVE

start();
